import { graphql, useStaticQuery } from 'gatsby'

const ProductMetadata = () => {
  const data = useStaticQuery(
    graphql`
      {
        allMdx(filter: {frontmatter: {templateKey: {eq: "product"}}}) {
          edges {
            node {
              frontmatter {
                slug
                purchaseType
                productID
                price
                fullTitle
                title
                kitPrice
                rentalPrices {
                  discountPercent
                  price
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allMdx.edges
}

export default ProductMetadata
